<template>
  <section
    id="core-competencies-new"
    style="background-color:#ebedee;"
  >
    <div
      class="pt-10 pb-15"
    >
      <base-subtitle
        title=""
        space="1"
        tag="h2"
        size="text-h5"
        align="center"
        class="text-uppercase"
      />

      <base-subheading
        align="center"
        title="what we do"
        class="text-uppercase"
        space="2"
      />

      <base-divider
        color="primary"
        align="center"
      />
    </div>
    <base-right-photo
      :src="require('@/assets/howell_6.jpeg')"
      subheading="TRADING OF ALL TYPES OF NITRILE AND NATURAL RUBBER GLOVES"
      html=""
      color="black--text"
    >
    </base-right-photo>
    <base-left-photo
      :src="require('@/assets/gallery-10.jpg')"
      subheading="TURNKEY PROJECT MANAGEMENT SPECIALIST FOR GLOVE MANUFACTURING LINE"
      html=""
      color="black--text"
    >
    </base-left-photo>
    <br>
    <br>
  </section>
</template>

<script>
  export default {
    name: 'SectionCoreCompetenciesNew',
  }
</script>
